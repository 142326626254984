import { Injectable } from '@angular/core';
import { ApiUrls } from '../../core/config';
import { SessionService } from '../../services/session.service';
import { Token, User } from '../../core/models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HeaderService } from '../../services/header.service';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private readonly loginUrl = ApiUrls.Login;

  constructor(private http: HttpClient, private sessionService: SessionService, private header: HeaderService) { }

  login(token: Token, coords: string) {
    const headers = { 'X-Seech-Token': `${token.token_type} ${token.access_token}`, 'Current-Position': coords };
    const context = this.header.skipAuthAndHeaders();

    return this.http.get(`${this.loginUrl}`, { headers, observe: 'response', context }).pipe(
      map((response) => {
        const user = response.body as User;
        if (user && user.sessions[0] && user.sessions[0].token && user.responseCode == 200) {
          this.sessionService.initialize(user);
        }
        return response;
      })
    );
  }

  logout() {
    this.sessionService.end();
  }

  gotoExternalLogin(parameters = {}) {
    this.sessionService.gotoExternalLogin(parameters);
  }
}

