import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiUrls, BlobUrls, HttpParameters } from "@src/app/core/config";
import { connectionType, pinPrompt } from "@src/app/core/Constants";
import { BlobData, EntityWithCount, ImageUpload, PinstatusResponse, RecepientUser, SavedLocation, Token, User, UserConnectionSummary, UserImage } from "@src/app/core/models/model";
import { HeaderService } from "@src/app/services/header.service";
import { SKIP_OPTION } from "@src/app/services/interceptor.service";
import { StorageService } from "@src/app/services/storage.service";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, switchMap, map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit {

  private signupUrl = ApiUrls.Signup;
  private savedLocationUrl = ApiUrls.SavedLocationUrl;
  private userUrl = ApiUrls.User;
  private userInteractionUrl = ApiUrls.UserInteraction;
  private usersUrl = ApiUrls.Users;
  private coords: string = this.get('coords');
  readonly radius: number = this.get('radius');

  downloadPath: string = BlobUrls.Download + '/';

  constructor(private http: HttpClient, private router: Router, private storageService: StorageService, private header: HeaderService) { }

  ngOnInit(): void { }

  public get(key: string): any {
    let currentUser = this.storageService.getCurrentUser();

    if (currentUser) {

      switch (key) {
        case 'id':
          return currentUser.id;
        case 'username':
          return currentUser.username;
        case 'imageUrl':
          return currentUser.imageURL;
        case 'coords':
          return currentUser.coordinates;
        case 'radius':
          return currentUser.radius || 100;
        case 'locationPref':
          return currentUser.locationPref || 'default-location';
        case 'default-location':
          return this.DefaultLocation(currentUser.savedLocations);
        default: alert('Incorrect user key: ' + key);
      }
    }

    else { return ''; }
  }

  public getCurrentUserId() {
    return this.storageService.getCurrentUser().id;
  }

  public getCurrentUserName() {
    return this.storageService.getCurrentUser().username;
  }

  getCurrentUserImageUrl() {
    return this.storageService.getCurrentUser().imageURL;
  }

  getCurrentUserBannerUrl() {
    return this.storageService.getCurrentUser().bannerURL;
  }

  public set(key: string, updatedvalue: any): any {
    let currentUser = this.storageService.getCurrentUser();

    if (currentUser) {

      switch (key) {
        case 'username': {
          currentUser.username = updatedvalue;
          break;
        }
        case 'coords': {
          currentUser.coordinates = updatedvalue;
          break;
        }
        case 'radius': {
          currentUser.radius = updatedvalue;
          break;
        }
        case 'locationPref': {
          currentUser.locationPref = updatedvalue;
          break;
        }
        case 'default-location': {
          this.DefaultLocation(updatedvalue);
          break;
        }
        default: alert('Incorrect user key: ' + key);
      }

      this.storageService.setCurrentUser(currentUser);
    }

    else { return ''; }
  }

  DefaultLocation(savedLocations: SavedLocation[]) {
    for (let location of savedLocations) {
      if (location.isDefault) {
        return location;
      }
    }
  }

  UpdateStorage(key: string, data) {
    switch (key) {
      case 'profile': {
        this.storageService.setCurrentUser(data);
        break;
      }
      case 'settings': {
        this.storageService.updateUserSettings(data);
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
  }

  //=========================== signup ================================================

  signup(signup_token: string, user: User): Observable<any> {
    const headers = { 'X-Seech-Token': 'bearer ' + signup_token, 'Current-Position': this.storageService.getCurrentPosition(), 'Content-Type': 'application/json' };
    const context = this.header.skipAuthAndHeaders();
    return this.http.post(`${this.signupUrl}`, user, { headers, context });
  }

  checkAvailability(data) {
    return this.http.get<any>(`${this.userUrl}/property/available`, { params: new HttpParams({ fromObject: data }) });
  }

  getUsers(query?, page?, size?): Observable<User[]> {
    const params = new HttpParams()
      .set('query', query)
      .set('page', page)
      .set('size', size)

    if (!this.coords) {
      this.coords = this.storageService.getCurrentUser().coordinates
    }
    return this.http.get<User[]>(`${this.usersUrl}/${this.coords}/${this.radius}`, { params });
  }

  getUsersWithCount(subject_id: string, query: string = '', connectionType: string = '', page?: number, size?: number) {
    const params = new HttpParams()
      .set('subjectId', subject_id)
      .set('query', query)
      .set('page', page)
      .set('size', size)

    return this.http.get<EntityWithCount<User>>(`${this.userUrl}/interaction/${connectionType}`, { params });
  }

  getUsersWithCountSummary(subject_id:string, query: string = '', size?: number) {
    const params = new HttpParams()
      .set('subjectId', subject_id)
      .set('query', query)
      .set('size', size)

    return this.http.get<UserConnectionSummary>(`${this.userUrl}/interaction/summary`, { params });
  }

  getConnectedUsersWithLocation(query?: string): Observable<RecepientUser[]> {
    return this.http.get<RecepientUser[]>(`${this.usersUrl}/connections/${query}`);
  }

  getUnConnectedUsers(query?: string): Observable<RecepientUser[]> {
    return this.http.get<RecepientUser[]>(`${this.usersUrl}/unconnected/${query}`);
  }

  getAllUser(): Observable<User> {
    return this.http.get<User>(`${this.userUrl}`);
  }

  getAllUserNotCurrent(location: string, searchEntry: string): Observable<User[]> {
    const params = new HttpParams()
      .set('query', searchEntry)
    return this.http.get<User[]>(`${this.userUrl}/not-current/${location}`, { params });
  }

  searchAllUserNotCurrent(location: string, terms: Observable<string>): Observable<User[]> {
    return terms.pipe(
      distinctUntilChanged(),
      filter(term => term.length > 0),
      switchMap(term => this.getAllUserNotCurrent(location, term))
    );
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${this.userUrl}/${id}`);
  }

  getUserByUsername(username: string): Observable<User> {
    return this.http.get<User>(`${this.userUrl}/username/${username}`);
  }

  getUserProfile(id: string): Observable<User> {
    return this.http.get<User>(`${this.userUrl}/userprofile/${id}`);
  }

  getSuggestedUsers(size: number, query: string, location: string = ''): Observable<User[]> {
    let params = HttpParameters.params({ size, query, location });
    return this.http.get<User[]>(`${this.usersUrl}/suggested-user`, { params });
  }

  getUserRecepient(id: string): Observable<User> {
    return this.http.get<User>(`${this.userUrl}/recepient/${id}`, {context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)});
  }

  getBlob(id: string): Observable<BlobData[]> {
    return this.http.get<BlobData[]>(`${this.userUrl}/userblob/${id}`);
  }

  update(user: User): Observable<any> {
    return this.http.patch(`${this.userUrl}`, user);
  }

  updateSavedLocation(savedLocation: SavedLocation): Observable<SavedLocation> {
    return this.http.patch(`${this.savedLocationUrl}`, savedLocation);
  }

  userImageUpload(imageUpload: ImageUpload): Observable<User> {

    return this.http.patch<User>(`${this.userUrl}/user-image`, imageUpload);
  }

  updateUsername(username: string): Observable<any> {
    return this.http.patch(`${this.userUrl}/${username}`, null);
  }

  delete(isDeleted: boolean): Observable<any> {
    return this.http.patch(`${this.userUrl}/delete/${isDeleted}`, null);
  }
  
  restore(isDeleted: boolean, token: Token){
    const headers = { 'Authorization': `${token.token_type} ${token.access_token}`, 'Current-Position': this.storageService.getCurrentPosition() };
    const context = this.header.skipAuthAndHeaders();
    return this.http.patch(`${this.userUrl}/delete/${isDeleted}`, null, { headers, observe: 'response', context });
  }

  deactivate(isDeactivated: boolean): Observable<any> {
    return this.http.patch(`${this.userUrl}/deactivate/${isDeactivated}`, null);
  }

  activate(isDeactivated: boolean, token: Token): Observable<any> {
    const headers = { 'Authorization': `${token.token_type} ${token.access_token}`, 'Current-Position': this.storageService.getCurrentPosition() };
    const context = this.header.skipAuthAndHeaders();
    return this.http.patch(`${this.userUrl}/deactivate/${isDeactivated}`, null, { headers, observe: 'response', context });
  }


  getSettings(id: string): Observable<any> {
    return this.http.get(`${this.userUrl}/${'settings'}/${id}`);
  }

  updateSettings(setting): Observable<any> {
    return this.http.patch(`${this.userUrl}/${'settings'}`, setting);
  }


  getUserImage(userImage: string): UserImage { // deprecated
    //const emptyImage = './assets/images/unknown-user.png';
    return { 'background-image': userImage ? 'url(' + (this.downloadPath + userImage) + ')' : '' }
  }

  getUserImageString(userImage: string) {
    //const emptyImage = './assets/images/unknown-user.png';
    return userImage ? 'url(' + (this.downloadPath + userImage) + ')' : '';
  }

  getCurrentUserImage() { // deprecated
    return this.getUserImage(this.get('imageUrl'));
  }

  getCurrentUserImageString() {
    return this.getUserImageString(this.get('imageUrl'));
  }

  getUserOnlineState(userId): Observable<any> {
    return this.http.get(`${this.userUrl}/${'online-status'}/${userId}`);
  }

  navigateToProfilePage(username: string) {
    this.router.navigate(['/user', username]);
  }

  messageUser(user: User) {
    if (user && user.id) {
      this.router.navigate(['/messages'], { queryParams: { 'ref': user.id }, queryParamsHandling: 'merge' });
    }
  }

  pin_prompt(user: User): string {
    switch (user?.pinStatus?.relationship) {
      case connectionType.pinned:
        return pinPrompt.unpin;
      case connectionType.connected:
        return pinPrompt.disconnect;
      case connectionType.pinning:
        return pinPrompt.connect;
      default:
        return pinPrompt.pin;
    }
  }

  isConnected(user: User) {
    return this.pin_prompt(user) === pinPrompt.disconnect;
  }

  isPinned(user: User) {
    return this.pin_prompt(user) === pinPrompt.unpin;
  }

  isPinning(user: User) {
    return this.pin_prompt(user) === pinPrompt.connect;
  }

  isUnrelated(user: User) {
    return this.pin_prompt(user) === pinPrompt.pin;
  }

  pin(partner_id: string, action: string) {
    return this.http.get<PinstatusResponse>(`${this.userInteractionUrl}/${action}/${partner_id}`);
  }

  unblockAndPin(partner_id: string, action: string) {
    return this.http.get<PinstatusResponse>(`${this.userInteractionUrl}/unblock/${action}/${partner_id}`);
  }

  profileRequest(partner_id: string, action: string, notificationId = "") {
    const params = new HttpParams().set('notificationId', notificationId)
    return this.http.get<PinstatusResponse>(`${this.userInteractionUrl}/profile-request/${action}/${partner_id}`, {params});
  }

  getURL(imageURL: string): string {
    return imageURL ? (BlobUrls.Download + '/' + imageURL) : '';
  }


  updateOnlineStatuses(users: User[]) {
    const userIds = users.map(user => user.id);
    return this.http.post<User[]>(`${this.userUrl}/${'online-status'}`, userIds, {context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)}).pipe(
      map(result => {
        return users.map(user => {
          const onlineStatus = result.find(res => res.id == user.id) || {};
          user = { ...user, ...onlineStatus };
          return user;
        });
      }));
  }

  toggleUserEntityStatus(entityId: string, entityType: string, action: boolean){
    const params = new HttpParams().set('entityType', entityType).set('action', action)
    return this.http.get<boolean>(`${this.userUrl}/toggle-entity-hiddenstatus/${entityId}`, {params});
  }

 
}

